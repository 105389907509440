<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="保养名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入保养名称" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="8" :sm="24">
              <a-form-item label="型号" prop="model">
                <a-input v-model="queryParam.model" placeholder="请输入型号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="保养时间" prop="upkeepTime">
                <a-date-picker
                  style="width: 100%"
                  v-model="queryParam.upkeepTime"
                  format="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆保养里程" prop="odom">
                  <a-input v-model="queryParam.odom" placeholder="请输入上次车辆保养里程" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:upkeep:add']">
          <a-icon type="plus" />新增
        </a-button> -->
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:record:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <!-- <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:upkeep:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:record:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="upkeepTime" slot-scope="text, record">
          {{ parseTime(record.upkeepTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:record:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:record:edit']">
            <a-icon type="edit" />修改
          </a>
          <!-- <a-divider type="vertical" v-hasPermi="['tenant:iot:upkeep:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:upkeep:remove']"> <a-icon type="delete" />删除 </a> -->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreateForm from './modules/CreateForm'
import { recordList, exportRecord, delRecord } from '@/api/iot/record'

export default {
  name: 'Upkeep',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        vehicleId: null,
        name: null,
        model: null,
        upkeepTime: null,
        upkeepTimeNext: null,
        odom: null,
        odomUpkeep: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保养名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '型号',
          dataIndex: 'model',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆保养时里程(KM)',
          dataIndex: 'odom',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆保养时间',
          dataIndex: 'upkeepTime',
          scopedSlots: { customRender: 'upkeepTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保养门店名称',
          dataIndex: 'upkeepStore',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保养地址',
          dataIndex: 'upkeepAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆保养费用',
          dataIndex: 'upkeepCost',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保养处理人',
          dataIndex: 'upkeepPerson',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.queryParam.vehicleId = this.$route.query.vehicleId
    this.queryParam.name = this.$route.query.name
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车辆保养信息列表 */
    getList() {
      this.loading = true
      recordList(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        vehicleId: undefined,
        name: undefined,
        model: undefined,
        upkeepTime: undefined,
        upkeepTimeNext: undefined,
        odom: undefined,
        odomUpkeep: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
